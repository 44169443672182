import { tap, catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, of } from 'rxjs';
import { ToasterService } from 'angular2-toaster';
import { environment } from '../../environments/environment';
import { LanguageService } from './language.service';
import { PersonalFormFields, PersonalFormFieldsValues, Category, Payment, Social, Address, ProfileData } from '../models/index';
import { LoaderService } from './loader.service';
import { Settings } from '../models/profile-settings';

@Injectable()
export class ProfileService {

  profile: any = {};
  profile_subject = new Subject<any>();
  _toaster: ToasterService;
  _language: LanguageService;

  constructor(private http: HttpClient, private _inj: Injector) {
    this._toaster = this._inj.get(ToasterService);
    this._language = this._inj.get(LanguageService);
  }

  getSocialMediaAccounts() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.get<any>(`${environment.API}/influencer/profile/social`)
        .subscribe(
          (response: Social[]) => {
            LoaderService.hide();
            this.profile['social_data'] = response;
            this.profile_subject.next({ data: this.profile, setSocialData: true });
            // checking if after returning to instagram do we have code if yes save the instagram code
            const instacode = localStorage.getItem('instagram-code');
            if (instacode !== null) {
              this.profile['social_data']['instagram_code'] = instacode;
              this.setSocialMediaAccounts(this.profile['social_data']);
            }
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.profile_subject.next({ error: true });
            }
          }
        );
    });
  }

  setSocialMediaAccounts(social_accounts) {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.post<any>(`${environment.API}/influencer/profile/social`, social_accounts)
        .subscribe(
          response => {
            LoaderService.hide();
            if (response['status']) {
              Object.keys(social_accounts).forEach((val, key) => {
                if (this.profile['social_data'][val]) {
                  this.profile['social_data'][val] = social_accounts[val];
                } else {
                  this.profile['social_data'][val] = null;
                  this.profile['social_data'][val] = social_accounts[val];
                }
              });
              this._toaster.pop('success', this.getTranslatedString('MESSAGES.UPDATE_TITLE'),
                this.getTranslatedString('MESSAGES.UPDATE_MSG_SOCIAL'));
              // checking if the instagram code exists remove it
              // todo remove other instagram code after it is being saved using APIs
              localStorage.removeItem('instagram-code');
              localStorage.removeItem('insta-url');
            }
            // getting updated social media account data after setting it
            this.getSocialMediaAccounts();
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.profile_subject.next({ data: error.error['messages'], error: true });
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.ERROR_TITLE'),
                this.getTranslatedString('MESSAGES.TRY_AGAIN'));
            }

          }
        );
    });
  }

  getPaymentsData() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.get<any>(`${environment.API}/influencer/profile/payment`)
        .subscribe(
          (response: Payment) => {
            LoaderService.hide();
            if (response) {
              this.profile['payment_data'] = response;
            }
            this.profile_subject.next({ data: this.profile, setPaymentData: true });
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.profile_subject.next({ data: error.error['messages'], error: true });
            }
          }
        );
    });
  }

  setPaymentsData(payment_data) {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.post<any>(`${environment.API}/influencer/profile/payment`, payment_data)
        .subscribe(
          response => {
            LoaderService.hide();
            if (response['status']) {
              Object.keys(payment_data).forEach((val, key) => {
                if (this.profile['payment_data']) {
                  this.profile['payment_data'][val] = payment_data[val];
                } else {
                  this.profile['payment_data'] = new Object();
                  this.profile['payment_data'][val] = payment_data[val];
                }
              });
              this._toaster.pop('success', this.getTranslatedString('MESSAGES.UPDATE_TITLE'),
                this.getTranslatedString('MESSAGES.UPDATE_MSG_PAYMENT'));
            }
            this.profile_subject.next({ data: this.profile, setPaymentData: true });
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.profile_subject.next({ data: error.error['messages'], error: true });
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.ERROR_TITLE'),
                this.getTranslatedString('MESSAGES.TRY_AGAIN'));
            }
          }
        );
    });
  }

  getAllCategories() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.get<any>(`${environment.API}/influencer/profile/allcategories`)
        .subscribe(
          (response: Category[]) => {
            LoaderService.hide();
            if (response) {
              this.profile['all_categories'] = response;
            }
            this.profile_subject.next({ data: this.profile, setAllCategoriesData: true });
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.ERROR_TITLE'),
                this.getTranslatedString('MESSAGES.TRY_AGAIN'));
            }
          }
        );
    });
  }

  getCategoriesForInfluencer() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.get<any>(`${environment.API}/influencer/profile/categories`)
        .subscribe(
          response => {
            LoaderService.hide();
            if (response) {
              this.profile['influencer_categories'] = response['categories'];
            }
            this.profile_subject.next({ data: this.profile, setInfluencerCategoriesData: true });
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.ERROR_TITLE'),
                this.getTranslatedString('MESSAGES.TRY_AGAIN'));
            }
          }
        );
    });
  }

  setCategoriesForInfluencer(categories, sub_cat_removed = false) {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.post<any>(`${environment.API}/influencer/profile/categories`, categories)
        .subscribe(
          response => {
            LoaderService.hide();
            if (response['status']) {
              this.profile['influencer_categories'] = categories['categories'];
              this._toaster.pop('success', this.getTranslatedString('MESSAGES.UPDATE_TITLE'),
                this.getTranslatedString('MESSAGES.UPDATE_MSG_CATEGORIES'));
            }
            if (sub_cat_removed) {
              this.profile_subject.next({ data: this.profile, removedSubCategory: true });
            } else {
              this.profile_subject.next({ data: this.profile, setInfluencerCategoriesData: true });
            }

          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.profile_subject.next({ data: error.error['messages'], error: true });
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.ERROR_TITLE'),
                this.getTranslatedString('MESSAGES.TRY_AGAIN'));
            }
          }
        );
    });
  }

  getPersonalFields() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.get<any>(`${environment.API}/influencer/profile/personalfields`).subscribe(
        (response: PersonalFormFields) => {
          LoaderService.hide();
          if (response) {
            this.profile['fields'] = response;
          }
          this.profile_subject.next({ data: this.profile, setPersonalFields: true });
        },
        error => {
          LoaderService.hide();
          if (error.error['status'] === 500) {
            this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
          } else {
            this.profile_subject.next({ data: error.error['messages'], error: true });
          }
        }
      );
    });
  }

  getProfileData() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.get<any>(`${environment.API}/influencer/profile/data`)
        .subscribe(
          (response: ProfileData) => {
            LoaderService.hide();
            if (response) {
              this.profile['profile_data'] = response;
            }
            this.profile_subject.next({ data: this.profile, setProfileData: true });
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.profile_subject.next({ data: error.error['messages'], error: true });
            }
          }
        );
    });
  }

  setProfileData(profile_data) {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.post<any>(`${environment.API}/influencer/profile/data`, profile_data)
        .subscribe(
          response => {
            LoaderService.hide();
            if (response['status']) {
              const localData = JSON.parse(localStorage.getItem('user'));
              Object.keys(profile_data).forEach((val, key) => {
                if (this.profile['profile_data']) {
                  this.profile['profile_data'][val] = profile_data[val];
                } else {
                  this.profile['profile_data'] = new Object();
                  this.profile['profile_data'][val] = profile_data[val];
                }
                if (val === 'firstname') {
                  localData['firstname'] = profile_data[val];
                }
                if (val === 'lastname') {
                  localData['lastname'] = profile_data[val];
                }
              });
              localStorage.setItem('user', JSON.stringify(localData));
              this._toaster.pop('success', this.getTranslatedString('MESSAGES.UPDATE_TITLE'),
                this.getTranslatedString('MESSAGES.UPDATE_MSG_PROFILE'));
            }
            this.profile_subject.next({ data: this.profile, setProfileData: true });
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.profile_subject.next({ data: error.error['messages'], error: true });
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.ERROR_TITLE'),
                this.getTranslatedString('MESSAGES.TRY_AGAIN'));
            }
          }
        );
    });
  }

  getPersonalData() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.get<any>(`${environment.API}/influencer/profile/personal`)
        .subscribe(
          (response: PersonalFormFieldsValues) => {
            LoaderService.hide();
            if (response) {
              this.profile['personal_data'] = response;
            }
            this.profile_subject.next({ data: this.profile, setPersonalData: true });
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.profile_subject.next({ data: error.error['messages'], error: true });
            }
          }
        );
    });
  }

  setPersonalData(personal_data) {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.post<any>(`${environment.API}/influencer/profile/personal`, personal_data)
        .subscribe(
          response => {
            LoaderService.hide();
            if (response['status']) {
              Object.keys(personal_data).forEach((val, key) => {
                if (this.profile['personal_data']) {
                  this.profile['personal_data'][val] = personal_data[val];
                } else {
                  this.profile['personal_data'] = new Object();
                  this.profile['personal_data'][val] = personal_data[val];
                }
              });
              this._toaster.pop('success', this.getTranslatedString('MESSAGES.UPDATE_TITLE'),
                this.getTranslatedString('MESSAGES.UPDATE_MSG_PERSONAL'));
            }
            this.profile_subject.next({ data: this.profile, setPersonalData: true });
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.profile_subject.next({ data: error.error['messages'], error: true });
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.ERROR_TITLE'),
                this.getTranslatedString('MESSAGES.TRY_AGAIN'));
            }
          }
        );
    });
  }

  getAddressData() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.get<any>(`${environment.API}/influencer/profile/address`).subscribe(
        (response: Address) => {
          LoaderService.hide();
          if (response) {
            this.profile['address_data'] = response;
          }
          this.profile_subject.next({ data: this.profile, setAddressData: true });
        },
        error => {
          LoaderService.hide();
          if (error.error['status'] === 500) {
            this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
          } else {
            this.profile_subject.next({ data: error.error['messages'], error: true });
          }
        }
      );
    });
  }

  setAddressData(address_data) {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.post<any>(`${environment.API}/influencer/profile/address`, address_data).subscribe(
        response => {
          LoaderService.hide();
          if (response) {
            Object.keys(address_data).forEach((val, key) => {
              if (this.profile['address_data']) {
                this.profile['address_data'][val] = address_data[val];
              } else {
                this.profile['address_data'] = new Object();
                this.profile['address_data'][val] = address_data[val];
              }
            });
            this._toaster.pop('success', this.getTranslatedString('MESSAGES.UPDATE_TITLE'),
              this.getTranslatedString('MESSAGES.UPDATE_MSG_ADDRESS'));
          }
          this.profile_subject.next({ data: this.profile, setAddressData: true });
        },
        error => {
          LoaderService.hide();
          if (error.error['status'] === 500) {
            this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
          } else {
            this.profile_subject.next({ data: error.error['messages'], error: true });
            this._toaster.pop('error', this.getTranslatedString('MESSAGES.ERROR_TITLE'),
              this.getTranslatedString('MESSAGES.TRY_AGAIN'));
          }
        }
      );
    });
  }

  uploadUserImage(image) {
    requestAnimationFrame(() => {
      LoaderService.show();
      const header = new HttpHeaders();
      header.append('Accept', 'multipart/form-data');
      this.http.post<any>(`${environment.API}/influencer/profile/image`, image, { headers: header })
        .subscribe(
          response => {
            LoaderService.hide();
            this.setProfileImageUrl(response['picture'], null, true);
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.setProfileImageUrl(null, true, true);
            }
          }
        );
    });
  }

  uploadPassport(image: FormData) {
    requestAnimationFrame(() => LoaderService.show());
    const header = new HttpHeaders();
    header.append('Accept', 'multipart/form-data');
    return this.http.post<{ success: boolean }>
      (`${environment.API}/influencer/profile/uploadpassport`, image, { headers: header }).pipe(
        tap(v => { LoaderService.hide(); })
      );
  }

  deletePassport() {
    requestAnimationFrame(() => LoaderService.show());
    return this.http.delete<{ success: boolean }>(`${environment.API}/influencer/profile/image`)
      .pipe(
        catchError((e: { error: { status: number } }) => {
          if (e.error['status'] === 500) {
            this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
          } else {
            this._toaster.pop('error', this.getTranslatedString('MESSAGES.IMAGE_CANT_DELETED'));
          }
          return of({});
        }),
        tap(response => {
          LoaderService.hide();
          if (response['status']) {
            this.setProfileImageUrl(null);
            this._toaster.pop('success', this.getTranslatedString('MESSAGES.IMAGE_DELETED'));
          } else {
            this._toaster.pop('error', this.getTranslatedString('MESSAGES.IMAGE_CANT_DELETED'));
          }
        })
      );
    // requestAnimationFrame(() => {
    //   LoaderService.show();
    //   this.http.delete(`${environment.API}/influencer/profile/image`)
    //     .subscribe(
    //       response => {
    //         LoaderService.hide();
    //         if (response['status']) {
    //           this.setProfileImageUrl(null);
    //           this._toaster.pop('success', this.getTranslatedString('MESSAGES.IMAGE_DELETED'));
    //         } else {
    //           this._toaster.pop('error', this.getTranslatedString('MESSAGES.IMAGE_CANT_DELETED'));
    //         }
    //       },
    //       error => {
    //         LoaderService.hide();
    //         if (error.error['status'] === 500) {
    //           this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
    //         } else {
    //           this._toaster.pop('error', this.getTranslatedString('MESSAGES.IMAGE_CANT_DELETED'));
    //         }
    //       }
    //     );
    // });
  }

  getSavedProfileData() {
    return this.profile;
  }

  setProfileImageUrl(url, error = null, isUpload = false) {
    if (error) {
      this.profile_subject.next({ data: error.error['messages'], error: error });
      if (isUpload) {
        this._toaster.pop('error', this.getTranslatedString('MESSAGES.ERROR_TITLE'),
          this.getTranslatedString('MESSAGES.TRY_AGAIN'));
      }
    } else {
      if (url !== null && url !== '') {
        this.profile['upload_image'] = url;
      } else {
        this.profile['upload_image'] = '';
      }
      if (isUpload) {
        this._toaster.pop('success', this.getTranslatedString('MESSAGES.UPDATE_TITLE'),
          this.getTranslatedString('MESSAGES.UPDATE_MSG_IMAGE'));
      }
      const user = JSON.parse(localStorage.getItem('user'));
      user['picture'] = url;
      localStorage.setItem('user', JSON.stringify(user));
      this.profile_subject.next({ data: this.profile, setUserProfile: true });
    }
  }

  getProfileImageUrl() {
    if (this.profile['upload_image']) {
      if (this.profile['upload_image'] !== null && this.profile['upload_image'] !== '') {
        return this.profile['upload_image'];
      } else {
        return '';
      }
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && (user['picture'] !== null && user['picture'] !== '')) {
        this.profile['upload_image'] = user['picture'];
        return this.profile['upload_image'];
      } else {
        return '';
      }

    }
  }

  getTranslatedString(string) {
    let translated_string = '';
    this._language.getTranslationUsingKeyString(string)
      .subscribe(
        tranlatedData => {
          translated_string = tranlatedData;
        }
      );
    return translated_string;
  }

  emptyProfile() {
    this.profile = {};
  }

  deleteProfileImage() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.delete(`${environment.API}/influencer/profile/image`)
        .subscribe(
          response => {
            LoaderService.hide();
            if (response['status']) {
              this.setProfileImageUrl(null);
              this._toaster.pop('success', this.getTranslatedString('MESSAGES.IMAGE_DELETED'));
            } else {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.IMAGE_CANT_DELETED'));
            }
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.IMAGE_CANT_DELETED'));
            }
          }
        );
    });
  }

  getProfileSettings() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.get<any>(`${environment.API}/influencer/profile/settings`)
        .subscribe(
          (response: Settings) => {
            LoaderService.hide();
            if (response) {
              this.profile['settings'] = response;
            }
            this.profile_subject.next({ data: this.profile, setSettingsData: true });
          },
          error => {
            LoaderService.hide();
            if (error.error['status'] === 500) {
              this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
            } else {
              this.profile_subject.next({ data: error.error['messages'], error: true });
            }
          }
        );
    });
  }

  setProfileSettings(settings) {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.http.put<any>(`${environment.API}/influencer/profile/settings`, settings).subscribe(
        response => {
          LoaderService.hide();
          if (response) {
            this.profile['settings'] = settings;
            this._toaster.pop('success', this.getTranslatedString('MESSAGES.UPDATE_TITLE'),
              this.getTranslatedString('MESSAGES.UPDATE_MSG_ADDRESS'));
          }
          this.profile_subject.next({ data: this.profile, setSettingsData: true });
        },
        error => {
          LoaderService.hide();
          if (error.error['status'] === 500) {
            this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
          } else {
            this.profile_subject.next({ data: error.error['messages'], error: true });
            // this._toaster.pop('error', this.getTranslatedString('MESSAGES.ERROR_TITLE'),
            //   this.getTranslatedString('MESSAGES.TRY_AGAIN'));
          }
        }
      );
    });
  }

}
