import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss', '../login/general.login.component.scss']
})
export class EmailConfirmationComponent implements OnInit {

  environment = environment;

  constructor() {
  }

  ngOnInit() {
  }

}
