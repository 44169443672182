import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'track-page',
  template: ''
})
export class TrackPageComponent {
  private static ttdLoded = false;

  constructor() {
    if (environment.production && !TrackPageComponent.ttdLoded && typeof window['ttd_dom_ready'] === 'function') {
      TrackPageComponent.ttdLoded = true;
      // @ts-ignore
      ttd_dom_ready( function() {
        // @ts-ignore
        if (typeof TTDUniversalPixelApi === 'function') {
          // @ts-ignore
          const universalPixelApi = new TTDUniversalPixelApi();
          // @ts-ignore
          universalPixelApi.init('eyx9tzr', ['efny9oa'], 'https://insight.adsrvr.org/track/up');
        }
      });
    }
  }
}
