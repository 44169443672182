import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { MessangerCampaign, Message } from '../models/index';

@Injectable({ providedIn: 'root' })
export class MessageService {

  private chatSelected = new Subject<number>();

  constructor(private http: HttpClient) {
  }

  getCampaignWithMessage() {
    return this.http.get<MessangerCampaign[]>(`${environment.API}/influencer/message/campaigns`);
  }

  getMessagesOfCompaign(id) {
    return this.http.get<Message[]>(`${environment.API}/influencer/message/conversation/${id}`);
  }

  createMessage(message) {
    return this.http.post<any>(`${environment.API}/influencer/message/create`, message);
  }

  uploadMessageMedia(message_media) {
    const header = new HttpHeaders();
    header.append('Accept', 'multipart/form-data');
    return this.http.post<any>(`${environment.API}/influencer/message/media`, message_media, {headers: header});
  }

  selectedCampaign(campaign: any) {
    this.chatSelected.next(campaign);
  }

  getSelectedCampaign(): Observable<any> {
    return this.chatSelected.asObservable();
  }

  readMessage(id) {
    return this.http.post<any>(`${environment.API}/influencer/message/read/${id}`, null);
  }

}
