import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService, LanguageService, ProfileService} from '../../../../services';
import {ToasterService} from 'angular2-toaster';
import {ErrorService} from '../../../../services/error.service';
import {environment} from '../../../../../environments/environment';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: [
        './general.login.component.scss',
        './login.component.scss'
    ]
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    showSuccessMessage: boolean = false;
    returnUrl: string;
    api_error = {};

    constructor(public fb: FormBuilder, public _router: Router,
                private _user_service: UserService, public _route: ActivatedRoute, private _lang_service: LanguageService,
                private _profile: ProfileService, private _toastr: ToasterService, private errorService: ErrorService) {
        this.loginForm = fb.group({
            'username': ['', Validators.required],
            'password': ['', Validators.required]
        });
    }

    ngOnInit() {
        this._toastr.clear();
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] ? this._route.snapshot.queryParams['returnUrl'] : '/dashboard';
    }

    login = (user_values) => {
        this.api_error = {};
        this._user_service.login(user_values).subscribe(res => {

                if (res['access_token']) {
                    localStorage.setItem('authenticated', '1');
                    localStorage.setItem('user', JSON.stringify(res));
                    localStorage.setItem('token', res['access_token']);
                    this._user_service.tokenSubject.next(res['access_token']);
                    if (res['picture'] !== null && res['picture'] !== '') {
                        this._profile.setProfileImageUrl(res['picture']);

                    }
                    if (res['language'] === 'de-DE') {
                        this._lang_service.setLanguage('de');
                    } else {
                        this._lang_service.setLanguage('en');
                    }
                    this._user_service.getAllRequiredData().then(resposne => {
                        this._lang_service.addRouteToNavigation();
                    });
                    this.showSuccessMessage = true;
                    setTimeout(() => {
                      window.location.href = this.returnUrl;
                    }, 2000);
                }
            },
            error => {
                const messages = error.error.messages;
                this.api_error = messages;
                this.errorService.error(messages, ['user', 'login']);
            });
    }

}
